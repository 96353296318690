.header {
  height: var(--discover-header-height);
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--c-border);
  padding-left: var(--left-padding);
}

.header > * {
  flex-shrink: 0;
}

.addButton {
  min-width: 103px;
  margin-left: 52px;
}

.search {
  margin-left: 66px;
  width: 270px;
}

.searchResults {
  margin-left: 18px;
}

.dropdown {
  margin-left: 45px;
}
