.table {
  width: 100%;
  table-layout: fixed;
}

.table td {
  overflow: hidden;
  text-overflow: ellipsis;
}

.table th {
  padding-bottom: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #868686;
  white-space: nowrap;
  position: sticky;
  top: 0;
  background: #ffffff;
}

.table th:before {
  content: '';
  border-bottom: 1px solid #D6D6D6;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.tr {
  color: #1D1D1D;
}

.tr:hover td {
  background-color: rgba(0,0,0,0.03);
}

.tr td {
  padding-top: 14px;
  padding-bottom: 14px;
  font-weight: 400;
  font-size: 14px;
  border-top: 1px solid #D6D6D6;
  line-height: 24px;
  transition: all 0.15s ease-in;
}

.tr:first-child td {
  border-color: transparent;
}

.table tbody tr:last-child td {
  border-bottom: 0;
}

.tdName {
  width: 400px;
}

.tdOrder {
  width: 80px;
  text-align: center;
}

.tdCount {
  min-width: 200px;
}
