.holder {
  display: flex;
}

.button {
  width: 30px;
  height: 27px;
  color: #408CF6;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;
  border: 0;
  background: none;
  font-size: 0;
}

.button path {
  fill: currentColor;
}

.button.active {
  background: #DFECFF;
  color: #B4B0B0;
}