.header {
  padding: 24px;
  display: flex;
  justify-content: space-between;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
}

.backLink {
  margin-bottom: 8px;
  width: max-content;
}

.backLink, .backLink:focus, .backLink:hover, .backLink:visited, .backLink:link, .backLink:active {
  text-decoration: none;
}

.title {
  display: inline-block;
}

.title button {
  margin-left: 8px;
  position: relative;
  top: 1px;
}

.title button svg {
  width: 14px;
  height: auto;
}

.search {
  display: inline-block;
  margin-left: 32px;
  min-width: 300px;
}

.filter {
  display: inline-flex;
  margin-left: 32px;
}