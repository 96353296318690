.holder {
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 240px;
  height: 150px;
  position: relative;
}

.button {
  font-size: 12px;
  height: 26px;
}

.inactive {
  background: #F5F5F5;
  color: #868686;
  opacity: 0.5;
  border: 1px solid #F5F5F5;
  pointer-events: none;
}

.enabled {
  background: #f1f6ff;
  border-color: var(--c-blue-dimmed);
}

.logo {
  margin-bottom: 16px;
  font-size: 0;
}

.title {
  color: #1D1D1D;
  font-size: 16px;
  font-weight: var(--fw-medium);
  margin-bottom: 8px;
}

.description {
  color: #868686;
  font-size: 14px;
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.controls {
  position: absolute;
  top: 16px;
  right: 16px;
}

.logo :global .icon-workable svg {
  width: 40px;
  height: auto;
}

.loaderHolder {
  position: absolute;
  inset: 0 0 0 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}