.label {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  width: 20px;
}

.content {
  padding: 14px 0;
  z-index: 999;
}

.item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 16px;
  font-size: 12px;
  color: var(--c-blue);
  text-decoration: none !important;
}

.item:last-child {
  margin: 0
}

.itemDanger {
  composes: item;
  color: var(--c-red);
}

.itemDanger:hover {
  background-color: var(--c-red-dimmed);
}

.icon {
  width: 25px;
  text-align: left;
}

.item :global .icon-fa-download,
.item :global .icon-fa-trash {
  position: relative;
  top: 1px;
}

.item :global .icon-person svg,
.item :global .icon-fa-trash svg {
  width: 11px;
  height: auto;
}

.item :global .icon-plus svg {
  width: 12px;
  height: auto;
}

.item :global .icon-pencil svg {
  width: 14px;
  height: auto;
}

.item :global .icon-gear svg {
  width: 13px;
  height: auto;
}

.item :global .icon-pencil-2 svg {
  position: relative;
  width: 12px;
  height: auto;
  left: 2px;
}

.item :global .icon-eye svg {
  position: relative;
  top: 1px;
  width: 15px;
  height: auto;
}
.item :global .icon-fa-pause svg {
  position: relative;
  top: 1px;
}

.item :global path {
  fill: currentColor;
}