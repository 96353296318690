.radio {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid var(--c-border);
  transition: all 0.15s ease-in;
  position: relative;
  cursor: pointer;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  height: 60%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s ease-in;
  background: var(--c-blue);
}

.radio.checked,
.radio:hover {
  border-color: var(--c-blue);
}

.radio.checked .icon {
  opacity: 1;
  visibility: visible;
}

.input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  cursor: pointer;
}
