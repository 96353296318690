.stepper {
  margin-bottom: 24px;
}

.step {
  margin-top: 24px;
}

.select {
  width: fit-content;
  margin: 10px auto 0;
  font-family: Montserrat-Medium !important;
  font-size: 12px;
  color: var(--c-cyan);
}

.members {
  margin-top: 32px;
  max-height: 300px;
  overflow-y: auto;
}

.membersSectionTitle {
  font-family: Montserrat-Medium !important;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 4px;
}

.selectText {
  text-transform: lowercase;
}

.chevron {
  margin-left: 8px;
}

.chevron svg {
  fill: var(--c-cyan);
}

.counter {
  margin-top: 24px;
}

.counterCreditsInput {
  min-width: 120px;
}

.navigation {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.button {
  font-family: Montserrat-Medium !important;
  width: 151px;
}

.button + .button {
  margin-left: 24px;
}

.purchaseModalBackdrop {
  z-index: 1050 !important;
}