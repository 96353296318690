.react-kanban-board {
  padding: 0;
}

.react-kanban-column {
  margin: 0;
  padding: 0 8px 4px 8px;
  border-right: 1px solid var(--c-border);
  border-radius: 0;
  background: none;
}

.react-kanban-column-header {
  padding: 0;
  border-bottom: var(--c-border);
}