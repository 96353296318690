.counter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.field {
  margin: 0 12px;
  border: solid 2px #408cf6;
  border-radius: 4px;
  font-family: "Poppins" !important;
  font-weight: 500;
  text-align: center;
  padding: 6px 0;
  font-size: 18px;
  line-height: 24px;
  width: 40px;
}

.field::-webkit-outer-spin-button,
.field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.field {
  -moz-appearance: textfield;
}

.button {
  padding: 0;
  border: none;
  background: none;
}

.button .icon svg {
  width: 17px;
  height: 17px;
  fill: var(--c-cyan);
  transition: 0.2s;
}

.button:disabled .icon {
  opacity: 0.3;
}

.label {
  flex: 0 0 100%;
  margin-top: 4px;
  margin-bottom: 0;
  font-family: Montserrat-SemiBold !important;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
}
