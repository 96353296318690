.content {
  margin-top: 4px;
  display: flex;
  align-items: flex-start;
}

.content > div:first-child {
  margin-right: 110px;
  width: 450px;
}

.heading {
  display: flex;
  align-items: center;
}

.title {
  font-size: 26px;
  line-height: 1.23;
  font-weight: 400;;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.actionsButton {
  width: 24px;
  height: 24px;
  background: var(--c-input-bg);
  margin-left: 12px;
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.archivedLabel {
  margin-top: 5px;
  margin-left: 13px;
}

.company {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.21;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.companyImage {
  width: 20px !important;
  height: 20px !important;
  font-size: 10px !important;
  font-weight: 700 !important;
  margin: 0 5px
}

.companyImage img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.description {
  margin-top: 10px;
  color: var(--c-text-dimmed);
  font-size: 14px;
  line-height: 1.36;
}

.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-top: 7px;
}

.tags > * {
  margin-left: 10px;
  margin-bottom: 10px;
  cursor: initial;
}
