.contactButton {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  overflow: hidden;
  background: var(--c-blue);
  border: 0;
  font-size: 0;
  padding: 0;
  position: relative;
}

.contactButton:disabled {
  background: var(--c-disabled);
}

.contactButton > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
