.link {
  font-size: 12px;
  line-height: 15px;
  color: var(--c-blue);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  position: relative;
}

.link:before {
  content: "";
  position: absolute;
  border-bottom: 1px solid currentColor;
  bottom: -1px;
  left: 0;
  right: 0;
}

.link:hover:before {
  display: none;
}

.link.gray {
  color: var(--c-gray);
}

.leftIcon {
  position: relative;
  top: 1px;
  margin-right: 3px;
}

.rightIcon {
  position: relative;
  top: 0;
  margin-left: 4px;
}
