.holder {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -32px;
}

.item {
  margin-left: 32px;
  margin-bottom: 24px;
  color: var(--c-blue);
  position: relative;
}

.itemCount {
  position: absolute;
  bottom: -10px;
  right: -10px;
  border-radius: 50px;
  overflow: hidden;
  min-width: 14px;
  padding: 1px 0;
  font-size: 8px;
  background: #ffffff;
  color: var(--c-cyan);
  border: 1px solid var(--c-cyan);
}

.item svg path {
  fill: currentColor;
}

.item :global .icon-linkedin svg {
  width: 16px;
  height: 100%
}

.item :global .icon-twitter svg {
  position: relative;
  top: 2px;
  width: 18px;
  height: 100%
}

.item :global .icon-facebook svg {
  width: 8px;
  height: 100%
}

.item :global .icon-dribbble svg {
  width: 18px;
  height: 100%
}