.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal :global(.modal-content) {
  min-height: auto;
  padding: 25px;
  border-radius: 8px;
}

.modal .header {
  justify-content: center;
  padding: 0 !important;
  margin-bottom: 24px;
}
.modal .title {
  font-family: Montserrat-SemiBold !important;
  font-size: 18px;
  line-height: 22px;
}

.modal .subtitle {
  font-family: Montserrat-Medium !important;
  font-size: 12px;
  line-height: 15px;
  color: #868686;
  margin: 8px 0 0;
}

.modal .body {
  max-height: 75vh;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.close {
  position: absolute;
  top: 17px;
  right: 24px;
  cursor: pointer;
}

.close svg {
  fill: var(--c-cyan);
}

.modal .footer {
  justify-content: center;
  margin-top: 24px;
  padding: 0;
  border: none;
}

.modal .footer > * {
  margin: 0;
}

.footer .button {
  margin-right: 24px;
}

.footer .button:last-child() {
  margin-right: 0;
}
