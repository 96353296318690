.planCard {
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 28px 24px 30px 24px;
  text-align: center;
}

.planCard * {
  font-family: Montserrat-Bold !important;
}

.planCard.left {
  align-items: flex-start;
}

.orange.planCard {
  background: linear-gradient(67.11deg, #ff8d69 16.98%, #ffa082 100%);
}

.cyan.planCard {
  background: linear-gradient(67deg, #408cf6 17.14%, #8cbcff 100%);
}

.blue.planCard {
  background: linear-gradient(71.35deg, #0e1f4f 11.19%, #2044ab 100%);
}

.price {
  margin-bottom: 12px;
  font-size: 40px;
  line-height: 1.2
}

.price small {
  font-size: 16px;
  font-family: Montserrat-Medium !important;
}

.explanation {
  font-size: 12px;
  margin-bottom: 16px;
  font-family: Montserrat-Medium !important;
}

.divider {
  border-top: 2px solid #fff;
  align-self: stretch;
}

.name {
  margin-top: 16px;
  font-size: 32px;
  line-height: 48px;
}

.limit {
  margin-top: 27px;
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 1;
}

.limit small {
  font-size: 12px;
  margin-left: 3px;
  font-family: Montserrat-Medium !important;
}

.button {
  padding: 12px 65px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 8px;
  background: #fff;
  color: var(--c-cyan);
  cursor: pointer;
  transition: 0.2s;
  align-self: stretch;
  border: 0;
}

.button:hover {
  opacity: 0.8;
}