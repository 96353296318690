.container {
  overflow: auto;
  flex: 1;
  padding-bottom: var(--footer-height);
  
  --footer-height: 70px;
  --max-width: 880px
}

.container :global .max-width {
  padding-top: 0;
  max-width: var(--max-width);
}

.header {
  text-align: center;
  padding: 62px 0 30px 0;
}

.loader {
  padding: 200px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  text-align: center;
}

.title {
  margin-bottom: 30px;
}

.sectionTitle {
  margin-bottom: 20px;
}

.section {
  margin-bottom: 40px;
}

.list {
  font-size: 14px;
  line-height: 1.2;
  padding-left: 18px;
}

.list li {
  margin-bottom: 10px;
}

.list li:last-child {
  margin-bottom: 0;
}

.downloadTemplateLink {
  font-size: 14px;
  line-height: 1.2;
  display: inline-flex;
  gap: 8px;
  align-items: center;
  text-decoration: none !important;
  color: var(--c-blue) !important;
}

.navButton {
  min-width: 100px;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid var(--c-border);
  background: #ffffff;
}

.footerInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--footer-height);
}

.row {
  display: flex;
  align-items: center;
  gap: 16px;
}