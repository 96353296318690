.checkbox {
  width: 16px;
  height: 15px;
  border-radius: 2px;
  border: 1px solid var(--c-border);
  transition: all 0.15s ease-in;
  position: relative;
  cursor: pointer;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.65);
  opacity: 0;
  visibility: hidden;
  margin-top: 1px;
  margin-left: 1px;
  transition: all 0.15s ease-in;
}

.checkbox.checked,
.checkbox:hover {
  border-color: var(--c-blue);
}

.checkbox.checked .icon {
  opacity: 1;
  visibility: visible;
}
