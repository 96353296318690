.container {
  max-width: 1100px;
  margin: 0 auto;
}

.item {
  border-top: 1px solid var(--c-border-dimmed);
  margin: 0 auto;
}

.item:first-child {
  border-top: 0;
  margin-top: 0;
}

.pagination {
  margin-top: 70px;
}

.divider {
  border: 0;
  margin: 4px 0 0 0;
  border-bottom: 1px solid #D6D6D6;
}

.listHeader {
  padding-top: 28px;
}

.invitationsList {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}