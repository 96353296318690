.modal-slide-left-enter {
  transform: translateX(100%);
}
.modal-slide-left-enter-active {
  top: 0;
  position: sticky;
  transform: translateX(0%);
  transition: transform 350ms ease-in-out;
}
.modal-slide-left-exit {
  transform: translateX(0%);
}
.modal-slide-left-exit-active {
  top: 0;
  transform: translateX(-100%);
  transition: transform 350ms ease-in-out;
}

.modal-slide-right-enter {
  transform: translateX(-100%);
}
.modal-slide-right-enter-active {
  top: 0;
  position: sticky;
  transform: translateX(0%);
  transition: transform 350ms ease-in-out;
}
.modal-slide-right-exit {
  transform: translateX(0%);
}
.modal-slide-right-exit-active {
  top: 0;
  transform: translateX(100%);
  transition: transform 350ms ease-in-out;
}
