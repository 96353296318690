.holder {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 100px 0;
}

.holder img {
  margin-bottom: 15px;
  width: 141px;
}

.holder p {
  text-align: center;
  font-size: 16px;
}
