.body {
  padding: 25px 30px 48px;
}

.button {
  font-family: Montserrat-Medium !important;
  min-width: 151px;
  padding-left: 20px;
  padding-right: 20px;
}

.subtext {
  margin-top: 8px;
  font-size: 10px;
  line-height: 14px;
  color: var(--c-gray);
  text-align: center;
}