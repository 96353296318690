.breakdown {
  margin: 28px 0 37px 0;
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.breakdownItem {
  display: flex;
  align-items: baseline;
}

.breakdownItemTitle {
  font-size: 14px;
  min-width: 140px;
  text-align: left;
}

.breakdownItemValue {
  font-size: 20px;
  font-weight: var(--fw-medium);
  text-align: left;
}

.subtext {
  font-size: 10px;
  line-height: 16px;
  color: var(--c-gray);
  text-align: left;
}