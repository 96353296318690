.holder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background: var(--c-border-dimmed);
}

.subtitle {
  line-height: 1.5;
}

.formHolder {
  width: 400px;
  margin: 0 auto;
  background: #ffffff;
  text-align: center;
}

.button {
  min-width: 170px;
  font-size: 14px;
  height: 36px;
}

.error {
  color: var(--c-red);
  font-size: 12px;
  margin: 5px 0 0 0;
}
