.card {
  display: flex;
  padding: 16px 12px;
  margin-bottom: 16px;
  border: 1px solid var(--c-border);
  border-radius: 8px;
  transition: 0.2s;
  position: relative;
}

.card .cardCol {
  max-width: max-content;
  padding: 0;
}

.cardActive {
  border-color: var(--c-cyan);
  background: #eef5ff;
}

.card .cardType {
  padding-right: 16px;
}

.cardType svg {
  min-width: 40px;
}

.cardInfo {
  min-width: 150px;
}

.cardInfoLastNumber,
.cardInfoExpired {
  display: block;
  font-size: 14px;
  color: #1d1d1d;
  line-height: 24px;
}

.cardInfoLastNumber {
  font-family: Montserrat-Medium !important;
}

.cardInfoLastNumber:first-letter {
  text-transform: uppercase;
}

.cardInfoExpired {
  font-family: Montserrat !important;
}

.cardRadioButtonWrapper {
  position: relative;
  max-width: 18px;
  margin-left: auto;
}

.cardRadioButton {
  margin-left: auto;
  display: block;
  width: 30px;
  cursor: pointer;
}

.circleIcon {
  position: absolute;
  right: 0;
}

.circleIcon svg {
  fill: #868686;
  opacity: 0;
  transition: 0.2s;
}

.cardActive .cardRadioButton .circleIcon svg {
  fill: var(--c-cyan);
}

.circleIcon.circleIconActive svg {
  opacity: 1;
}

.trashIcon {
  max-width: max-content;
  transition: 0.2s;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 14px;
  z-index: 1;
}

.trashIcon:hover {
  opacity: 0.6;
}

.trashIcon svg {
  width: 13px;
  height: 13px;
  fill: #d40000;
}
