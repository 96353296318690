.holder {
  flex: 1 0;
  transition: all 0.15s ease-in;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.header {
  height: var(--discover-header-height);
  display: flex;
  justify-content: space-between;
  padding: 30px 23px 0 30px;
  flex-shrink: 0;
}

.header.hasBooleanSearch {
  padding: 10px 23px 0 30px;
}

.controls {
  display: flex;
  align-items: baseline;
}

.controls.hasBooleanSearch {
  align-items: center;
  position: sticky;
  height: 34px;
  margin: 0 -22px 0 -30px;
  padding: 0 22px 0 30px;
  border-bottom: 1px solid var(--c-border);
  left: 0;
  right: 0;
  top: 0;
  background: #ffffff;
  z-index: 1
}

.controlsItem {
  margin-left: 20px;
}

.controlsItem:first-child {
  margin-left: 0;
}

.viewMode {
  margin-top: -7px;
}

.list {
  position: relative;
  border-top: 1px solid var(--c-border);
  padding: 0 22px 20px 30px;
  flex: 1;
  overflow: auto;
}

.item {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid var(--c-border-dimmed);
  max-width: 1100px;
}

.item:first-of-type {
  border-top: 0;
  margin: 0;
}

.pagination {
  padding: 0 110px 0 23px;
}

.pagination:before {
  left: 23px;
  right: 23px;
}

@media screen and (max-width: 1366px), screen and (max-height: 768px) {
  .header:not(.hasBooleanSearch) {
    padding-top: 24px;
  }
  .item {
    max-width: 100%;
  }
}
