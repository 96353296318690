.container {
  flex: 1;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container td {
  vertical-align: top;
}

.tdDate {
  min-width: 110px;
}

.userLink {
  font-size: inherit;
  text-decoration: none;
}

.userLink:before {
  display: none;
}

.deleteButton :global .icon-fa-trash svg {
  width: 12px;
  height: auto;
}

.deleteButton :global .icon-fa-trash svg path {
  fill: var(--c-red);
}
