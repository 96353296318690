.text {
  text-align: center;
  margin-bottom: 35px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.restart {
  margin-top: -15px;
  margin-bottom: 35px;
  text-align: center;
}

.restartLink {
  color: var(--c-blue);
  text-decoration: underline;
  cursor: pointer;
}

.restartLink:hover {
  text-decoration: none;
}

.buttonRow {
  display: flex;
  justify-content: center;
  padding: 0 5px;
}

.button {
  min-width: 120px;
  height: 32px;
}