.row {
  display: flex;
  margin: 0 -6px 18px -6px;
}

.row > * {
  margin: 0 6px;
  flex: 1;
}

@media screen and (max-width: 1366px), screen and (max-height: 768px) {
  .row {
    margin-bottom: 10px;
  }
}
