.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: var(--fw-medium);
  gap: 4px;
  line-height: 16px;
  text-align: center;
  transition: all 0.15s ease-in;
}

.icon {
  display: flex;
  line-height: 1;
}

.icon path {
  fill: currentColor;
  font-weight: var(--fw-medium);
}

.themePrimary {
  color: var(--c-blue);
}

.themePrimary:disabled {
  opacity: 0.5;
}

.themePrimary:hover:not(:disabled) {
  color: var(--c-azure-blue);
}

.themeDanger {
  color: var(--c-red);
}

.themeDanger:disabled {
  opacity: 0.5;
}

.themeDanger:hover:not(:disabled) {
  color: var(--c-red);
}