.holder {
  background: var(--c-orange);
  color: #ffffff;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
}

.back {
  text-decoration: underline;
  cursor: pointer;
}

.back:hover {
  text-decoration: none;
}

.user {
  font-weight: var(--fw-semibold);
}