.iconButton {
  width: 30px;
  height: 26px;
  border-radius: 2px;
  position: relative;
  transition: all 0.15s ease-in;
  display: inline-block;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  font-size: 0;
  color: var(--c-border);
}

.iconButton.active,
.iconButton:hover {
  background: var(--c-blue-dimmed);
  color: var(--c-blue);
}

.iconButton > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.iconButton path {
  fill: currentColor;
}
