.popover {
  border: 0 !important;
  box-shadow: 0 0 15px 0 #00000026;
}

.hasExportList {
  max-width: unset !important;
  width: 300px !important;
}

.popoverInner {
  font-family: "Montserrat-Medium", sans-serif;
  color: var(--c-text);
  font-size: 12px;
}

.title {
  font-size: 14px;
  line-height: 17px;
}

.subtitle {
  line-height: 15px;
  margin-top: 1px;
  color: var(--c-text-dimmed);
}

.divider {
  border-top: 1px solid var(--c-border);
  margin: 8px 0;
}

.creditsCount {
  color: var(--c-blue);
}

:global .navbar-nav .popover-body {
  padding: 15px 16px 17px 23px !important;
  max-height: 500px !important;
}

.list {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item {
  font-size: 12px;
  line-height: 25px;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.item:hover {
 color: var(--c-blue) !important;
}

.invitations {
  margin: 11px -16px 0 -23px;
}

.invitationsItem {
  padding: 9px 16px 13px 23px;
  background: #F5F9FF;
  border-bottom: 1px solid var(--c-blue-dimmed);
}

.invitationsItem:first-child {
  border-top: 1px solid var(--c-blue-dimmed);
}

.invitationsItemText {
  font-size: 12px;
  line-height: 15px;
  color: var(--c-blue);
  margin: 0;
}

.invitationsItemText b {
  font-weight: 600;
}

.invitationsItemControls {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.invitationsItemControls > * {
  margin-right: 12px;
  cursor: pointer;
}

.invitationsItemDismiss {
  cursor: pointer;
  text-decoration: underline;
  color: var(--c-dark-gray);
  margin: 0
}

.invitationsItemDate {
  color: var(--c-dark-gray);
  font-size: 12px;
  white-space: nowrap;
  padding-left: 10px;
}

.invitationsItemDismiss:hover {
  text-decoration: none;
}

.notificationsCounter {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(55%, -40%);
}

