/* All CSS will apply to Components found within ../mobile */
/* Experience Component */
.condensed-experience-list-container {
  max-height: 60px;
  overflow: hidden;
}

/* Skills Component */
.condensed-skills-list-container {
  max-height: 38px;
  overflow: hidden;
}
.skills-tag {
  display: inline-block;
  background: var(--c-border-dimmed);
  color: var(--c-text-gray);
  border-radius: 15px;
  font-size: 12px;
  line-height: 20px;
  padding: 2px 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none !important;
  cursor: default;
}

.skills-tag-highlighted {
  background: var(--c-blue-dimmed);
  color: var(--c-blue);
}

/* Generics */
.expand-container {
  max-height: initial;
  overflow: initial;
}

.save-button {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.71px;
  text-align: right;
  color: #a8a8a8;
  cursor: pointer;
}

.save-button-active {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.71px;
  text-align: right;
  color: #208bfe;
}

/* Modal Generics */
.mobile-experience-container,
.mobile-skills-container {
  padding: 0 25px;
}

.add-project-header-icon-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: initial !important;
  align-items: center;
  padding: 5px;
}

.add-project-span:hover {
  cursor: pointer;
}

.add-project-title-container {
  justify-content: center;
  width: 100%;
  margin: initial;
}

.candidate-projects-mobile-header {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.13;
  letter-spacing: -0.81px;
  text-align: center;
  color: #373737;
  margin-top: 5px;
}

/* Notes Section */
.mobile-notes-container {
  min-height: 168px;
  background-color: #f2f2f2;
  padding: 25px 40px;
  margin-top: 25px;
}

/* Note Footer Section */
.notes-container {
  /* width: 100%; */
  min-height: 168px;
  background-color: #f2f2f2;
  margin-top: 15px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 25px;
  /* position: fixed;
  bottom: 0; */
}

.notes-container-content {
  width: 100%;
  padding: 23px 29px 17px 38px;
  min-height: 168px;
  height: 168px;
}

.notes-header-container {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-bottom: 19px;
  padding-right: 3px;
}

.notes-header {
  font-family: Montserrat-Bold !important;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.71px;
  color: #373737;
}

.note-preview-container {
  padding: 0px 10px;
}

.create-view-note-button {
  font-family: Montserrat-Medium !important;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.61px;
  text-align: right;
  color: #208bfe;
  cursor: pointer;
}

.empty-notes-container {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 52px;
}

.no-notes-text {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.61px;
  color: #373737;
  margin: 0px;
}

.copyright-text-container {
  width: 100%;
  height: 22px;
  text-align: center;
  margin-left: -8px;
}

.copyright-text {
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.33px;
  text-align: center;
  color: #9b9b9b;
}

/* Note Footer Detail */
.note-footer-detail-container {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-bottom: 40px;
}

.note-footer-note {
  font-family: Montserrat-Medium !important;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.61px;
  color: #373737;

  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 235px;
}

.note-footer-detail-info {
  font-size: 9px;
  letter-spacing: -0.46px;
  color: #a8a8a8;
}

/* Note Detail Section*/
.notes-list-container {
  padding-left: 8px;
  padding-right: 8px;
}

.note-detail-container {
  display: flex;
  flex-flow: column;
  border-bottom: 1px solid #d8d8d8;
  padding: 22px 0px;
}

.note-detail-container:first-child {
  padding-top: 0px;
}

.note-detail-container:last-child {
  border-bottom: none;
}

.note-header-container {
  margin-bottom: 7px;
  display: flex;
  flex-flow: column;
  font-size: 14px;
}

/* View Notes Subsection */
#view-notes-modal,
#view-notes-modal .modal-content {
  height: 100%;
}
#view-notes-modal .add-project-header {
  flex-flow: column;
  padding: 0.5rem !important;
}
#view-notes-modal .modal-content {
  padding-top: 0 !important;
  overflow: auto;
}

/* Create Note Subsection */

#create-note-modal .modal-content .modal-body {
  padding-top: 0px;
  margin-top: 10px;
  max-height: calc(100% - 128px);
}

#create-note-modal #unique-toast {
  top: 192px;
}

.create-note-container {
  padding-left: 6px;
  padding-right: 4px;
}
.create-note-header {
  font-family: Montserrat-Medium !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: -0.81px;
  text-align: center;
  color: #373737;
}

.create-note-save-button-container {
  text-align: right;
  padding-right: 29px;
  margin-bottom: 13px;
}

.create-note-save-button {
  opacity: 0.25;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  color: #208bfe;
  font-family: Montserrat-Medium !important;
  outline: none;
}

.create-note-save-button.active {
  opacity: 1;
  cursor: pointer;
}

#create-note-text-area {
  font-family: Montserrat-Medium !important;
  width: 100%;
  /* width: 279px; */
  height: 132px;
  border-radius: 3px;
  background-color: #f3f3f3;
  border: 1px solid transparent;
  margin-top: 42px;
  resize: none;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.71px;
  color: #373737;
  margin-bottom: 4px;
  padding: 11px;
}

/* Add Project Modal */

#mobile-add-project-modal .add-project-header {
  flex-flow: column;
  padding: 0.5rem !important;
}

#mobile-add-project-modal,
#mobile-add-project-modal .modal-content {
  height: 100%;
}
#mobile-add-project-modal .modal-title {
  width: 100%;
}
