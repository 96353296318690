.table {
  font-size: 14px
}

.table tbody td {
  padding: 2px 10px
}

.table tbody tr td:nth-child(1) {
  vertical-align: top;
  padding-left: 0;
  color: rgb(105, 115, 134);
}

.table tbody tr td:nth-child(2) {
  padding-right: 0;
  color: rgb(60, 66, 87);
}
