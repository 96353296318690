.title {
  font-family: Montserrat-Medium !important;
  line-height: normal;
  margin: 0;
  color: var(--c-black);
}

h1.title {
  font-size: 26px;
}

h2.title {
  font-size: 18px;
}

h3.title {
  font-size: 16px;
}

h4.title {
  font-size: 14px;
}
