.holder {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: -32px -32px 0 -32px;
}

.header {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
}

.title {
  display: inline-block;
}

.search {
  display: inline-block;
  min-width: 300px;
}

.filter {
  display: inline-flex;
  margin-left: 32px;
}

.content {
  flex: 1;
  padding-bottom: var(--pagination-height);
}

.pagination {
  padding-left: 24px;
  padding-right: 32px;
  flex-shrink: 0;
  position: fixed;
  bottom: 0;
  left: 330px;
  right: 0;
  background: #ffffff;
  z-index: 1;
}

.table {
  width: 100%;
  table-layout: fixed;
}

.table td {
  overflow: hidden;
  text-overflow: ellipsis;
}

.table th {
  padding-bottom: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #868686;
  white-space: nowrap;
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 999;
}

.table th:before {
  content: '';
  border-bottom: 1px solid #D6D6D6;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.tr td {
  padding-top: 14px;
  padding-bottom: 14px;
  font-weight: 400;
  font-size: 14px;
  color: #1D1D1D;
  border-top: 1px solid #D6D6D6;
}

.tr:first-child td {
  border-color: transparent;
}

.table tbody tr:last-child td {
  border-bottom: 0;
}

.tdName {
  padding-left: 24px;
  width: 300px;
}

.tdActions {
  padding-right: 20px;
}

.tdActions :global .icon-pencil svg {
  width: 16px;
  height: auto;
}

.tdActions :global .icon-pencil svg path {
  fill: var(--c-blue);
}

.tdActions :global .icon-fa-trash svg {
  width: 12px;
  height: auto;
}

.tdActions :global .icon-fa-trash path {
  fill: var(--c-red);
}
