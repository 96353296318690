.holder {
  border: 1px dashed var(--c-border);
  border-radius: 8px;
  transition: 0.2s;
  position: relative;
  height: 94px;
}

.placeholderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 14px;
  cursor: pointer;
}

.placeholderContainer span {
  font-size: inherit;
}

.fileInput {
  cursor: pointer;
  position: absolute;
  inset: 0 0 0 0;
  opacity: 0;
  z-index: 1;
  -webkit-appearance: none;
}

.selectedFileContainer {
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  font-size: 14px;
}

.row {
  display: flex;
  align-items: center;
}

.faFile path {
  fill: #1D6F42;
}

.faTrash {
  font-size: 0;
}

.faTrash svg {
  width: 18px;
  fill: var(--c-red);
}