.discoverUser {
  display: flex;
  align-items: flex-start;
}

.section {
  padding-top: 2px;
  position: relative;
  overflow: hidden;
}

.mainSection {
  composes: section;
  width: 450px;
  flex-shrink: 0;
}

.addButton {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 103px;
}

.addButton svg {
  font-size: 0;
  width: 12px;
  height: auto;
}

.checkboxHolder {
  padding-top: 3px;
  margin-right: 11px;
}

.title {
  font-size: 16px;
  line-height: 20px;
  color: var(--c-blue);
  margin-bottom: 8px;
  margin-right: 110px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.address {
  margin-bottom: 8px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.addedCompanies {
  color: #606060;
  font-size: 12px;
  min-width: 80px;
  margin-left: 50px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dateRange {
  font-size: 12px;
  line-height: 1.25;
  color: var(--c-text-dimmed);
  margin-top: 2px;
}

.position {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--c-text-dimmed);
}

.positionTitle {
  color: var(--c-text-gray);
}

.footer {
  margin-top: 15px;
  display: flex;
}

.footerSection {
  margin-right: 100px;
}

.footerSectionTitle {
  margin-bottom: 7px;
}

.footerRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-auto-flow: row dense;
  grid-gap: 8px;
}

.footerRow path {
  fill: #ffffff
}

.footerRow svg {
  max-width: 10px;
  max-height: 10px;
}

.contactButton {
  margin-right: 10px;
  display: inline-block;
}

.lastViewed {
  margin-top: 21px;
  display: flex;
  align-items: center;
  color: var(--c-text-dimmed);
}

.lastViewedDate {
  position: relative;
  top: -1px;
  margin-left: 5px;
  text-transform: capitalize;
}

.lastViewed svg {
  font-size: 0;
  width: 17px;
  height: auto;
}
.lastViewed svg path {
  fill: currentColor;
}

.divider {
  margin: 0 27px 0 18px;
  border-left: 1px solid var(--c-border-dimmed);
  align-self: stretch;
}

.experienceTitle {
  margin-bottom: 9px;
}

.noData {
  font-size: 12px;
  color: var(--c-text-dimmed);
}

.experienceList {
  margin-bottom: 18px;
}

.experienceList .experienceItem {
  margin-bottom: 10px;
}

.experienceList .experienceScore {
  margin-top: 2px;
  font-size: smaller;
  margin-bottom: 0;
}

.experienceList .experienceItem:last-child {
  margin-bottom: 0;
}

.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -10px;
}

.tags > * {
  margin-left: 10px;
  margin-bottom: 10px;
}
