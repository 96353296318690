.project {
  display: flex;
  justify-content: center;
  margin-bottom: 21px;
}

.table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.table th {
  color: #a6a2a2;
  font-weight: 500;
  padding-bottom: 5px;
  border-bottom: 1px solid #F2F2F2;
}

.table td {
  padding: 4px 5px 4px 0;
  vertical-align: middle;
}

.table tr:first-child td {
  padding-top: 9px;
}

.table td:last-child {
  padding-right: 0
}

.tdName {
  max-width: 160px;
}

.tdEmail {
  max-width: 200px;
}

.tdAdded {
  color: #A6A2A2;
}

.emailHolder {
  display: flex;
  align-items: center;
}

.nameHolder {
  display: flex;
  align-items: center;
}

.initials {
  flex-shrink: 0;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.name {
  composes: ellipsis;
  margin-left: 12px;
}

.role {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.selectedRole {
  cursor: initial;
  font-weight: 600;
  color: #3F3F3F;
  background: transparent;
}

/* @todo duplicated styles */
.roleItem {
  padding-left: 13px;
  padding-right: 13px;
  margin-left: -13px;
  margin-right: -13px;
}

.divider {
  border-color: #F2F2F2;
  margin: 7px 0;
}

.roleIcon {
  position: relative;
  top: -1px;
  margin-left: 7px;
}

.roleIcon path {
  fill: #3f3f3f;
}

.roleDropdownContent {
  padding: 5px 13px;
  min-width: 145px;
  width: auto;
}
