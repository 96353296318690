.holder {
  display: flex;
  gap: 24px;
  align-items: flex-start;
  padding-right: 44px;
}

.col {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.purchase {
  composes: col;
  flex: 0 0 318px;
}

.info {
  composes: col;
  flex: 1;
}

.progressBarCard {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.progressBarCardRow {
  display: flex;
  justify-content: space-between;
}

.progressBarCardValue {
  color: var(--c-black);
  font-size: 14px;
}

.progressBarCardValue.clickable {
  text-decoration: underline;
  cursor: pointer;
}