.label {
  font-size: 12px;
}

.colorList {
  display: grid;
  margin: 12px 0;
  grid-template: repeat(1, 32px) / repeat(5, 32px);
}

.colorItem {
  cursor: pointer;
  box-sizing: content-box;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-right: 8px;
  transition: all 0.15s ease-in;
  position: relative;
}

.colorItem:before {
  content: '';
  opacity: 0;
  border: 2px solid var(--c-blue);
  transition: all 0.15s ease-in;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  width: 28px;
  height: 28px;
}

.colorItem .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s ease-in;
}

.colorItem .icon path {
  fill: #ffffff
}

.colorItem:hover:before,
.colorItem.active .icon {
  opacity: 1;
  visibility: visible;
}

.colorItem:last-child {
  margin-right: 0;
}

.daysOnStageInput {
  width: 83px;
}