:global .ReactCollapse--collapse {
  transition: height 300ms;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.title {
  color: var(--c-black);
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.optional {
  color: var(--c-dark-gray);
  font-size: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.openButton {
  -webkit-appearance: none;
  background: none;
  border: 0;
  position: relative;
  top: -1px;
  transition: 0.15s ease-in;
}

.isOpened .openButton {
  top: 1px;
  transform: rotate(180deg);
}

.content {
  padding-top: 16px;
}