.holder {
  display: inline-flex;
  align-items: center;
  margin-right: 57px;
}

.item {
  margin-left: 32px;
  color: var(--c-blue);
  font-size: inherit;
}

.item svg path {
  fill: currentColor;
}

.itemDisabled {
  composes: item;
}

.itemDisabled svg path {
  fill: var(--c-border);
}

.item :global .icon-envelope-outline {
  position: relative;
  top: -1px;
}

.item:first-child {
  margin: 0;
}

.dropdown {
  padding: 8px 12px;
  border: 1px solid var(--c-blue);
  border-radius: 0 16px 16px 16px;
  width: unset;
  min-width: 200px;
}

.dropdownNarrow {
  composes: dropdown;
  min-width: 100px;
}

.dropdownItem {
  color: var(--c-blue);
  font-size: 12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
}

.dropdownItem:last-child {
  margin: 0
}

.dropdownItem button {
  font-size: initial;
}

.dropdownItemActions {
  margin-left: 12px;
}

.verificationIcon {
  margin-right: 8px;
  position: relative;
  top: 2px;
}

/* because icon looks cut, adding extra width to prevent it */
.dropdownItemActions :global .icon-copy svg {
  width: 14px;
}

.toastContainer :global .clipboard-success-toast-container {
  right: -4px;
  top: -1px !important;
}