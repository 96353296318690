.container {
  position: relative;
  padding-top: 20px;
  max-width: 1100px;
  margin: 0 auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.pagination {
  margin-top: 70px;
}

.divider {
  margin: 30px 0 20px 0;
  border: 0;
  border-top: 1px solid var(--c-border);
}

.notifications {
  position: fixed;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
}
