.admin-table {
  padding: 0;
  border: none;
  table-layout: auto;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
}

.admin-table thead th {
  font-weight: 500;
  color: var(--c-text-dimmed);
  padding: 8px 5px 5px 5px;
  border: 0;
}

.admin-table a {
  font-size: inherit;
  line-height: inherit;
}

.admin-table .admin-table-actions {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  margin-left: -7px;
}

.admin-table .admin-table-actions > * {
  margin-left: 7px;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
}
.admin-table .admin-table-actions > * {
  color: inherit;
}

.admin-table td {
  border-top: 1px solid var(--c-border);
  padding: 19px 5px;
  word-wrap: break-word;
}

.admin-table th {
  padding: 3px 5px;
  word-wrap: break-word;
}

.admin-table td:first-child, .admin-table th:first-child {
  padding-left: 0;
}

.admin-table thead td, .admin-table thead th {
  font-size: 12px;
  color: var(--c-dark-gray);
  padding: 3px 5px 12px;
  text-align: left;
}

.admin-table thead td:last-child, .admin-table thead th:last-child {
  width: 100px;
}
