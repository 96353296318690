.holder {
  text-align: center;
  padding: 100px 0;
}

.text {
  font-size: 18px;
}

.icon {
  margin-top: 64px;
}

.icon svg {
  width: 112px;
  height: auto;
}