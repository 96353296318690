.holder {
  background: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 4px;
  max-width: 30px;
  height: 16px;
  text-align: center;
  line-height: 13px;
  font-size: 10px;
  font-weight: 600;
  color: var(--c-blue);
  border: 2px solid var(--c-blue);
  border-radius: 50px;
  cursor: pointer;
}