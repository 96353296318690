.content {
  padding: 32px;
}

.mainInfo {
  display: flex;
  align-items: flex-end;
}

.name {
  font-size: 18px;
  margin-bottom: 3px;
  color: #1D1D1D;
  font-weight: var(--fw-medium);
}

.registered {
  font-size: 12px;
  color: var(--c-gray);
  margin: 0;
}

.divider {
  margin: 24px 0 32px;
  border: 0;
  border-bottom: 1px solid #D6D6D6;
}

.title {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.infoItem {
  display: flex;
  align-items: baseline;
  margin-bottom: 16px;
}

.infoItemLabel {
  font-size: 14px;
  color: #868686;
  width: 115px;
  flex-shrink: 0;
}

.infoItemValue {
  color: #1D1D1D;
  font-size: 12px;
  width: 280px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.editButton svg {
  width: 18px;
  height: auto;
  color: var(--c-cyan);
  margin-left: 18px;
}