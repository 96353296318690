.dropdownContent {
  max-height: 200px;
  overflow-y: auto;
  width: 255px;
  padding: 20px 15px 15px 20px !important;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.value {
  color: var(--c-gray);
  font-size: 12px;
  margin-right: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* @todo copypaste */
/* Contact Toast Section */
.successContainer {
  position: absolute;
  top: 1px !important;
  right: 0px;
  z-index: 1000;
  background-color: black !important;
  opacity: 0.75;
  padding: 0px 5px !important;
  border: 1px solid transparent;
  border-radius: 7px;
  box-shadow: none !important;
}

.successContent {
  background-color: black !important;
  font-size: 12px;
  color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px !important;
}

.iconWithEmailWrapper {
  display: flex;
  overflow: hidden;
}

.icon {
  margin-right: 5px;
}

@keyframes pulse {
  from,
  25%,
  75%,
  to {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }
}

.pendingIcon {
  animation-name: pulse;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
