.flexVertical {
  display: flex;
  flex-direction: column;
}

.holder {
  composes: flexVertical;
  flex: 1;
}

.title {
  color: var(--c-black);
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin: 88px 0 0 0;
}

.message {
  margin: 16px 0 0 0;
  color: var(--c-dark-gray);
  text-align: center;
  font-size: 16px;
  line-height: 1.25;
}

.button {
  width: 100%;
  margin-bottom: 32px;
}

.formFieldsHolder {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.divider {
  border-top: 1px solid var(--c-border);
  margin: 8px 0;
}

.addButton {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: var(--c-blue);
  font-size: 12px;
  -webkit-appearance: none;
  background: none;
  border: 0;
}

.addButton svg {
  margin-right: 8px;
}

.removeButton {
  composes: addButton;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
}

.removeButtonVisible {
  opacity: 1;
  visibility: visible;
}

.removeButton svg {
  width: 12px;
  margin: 0;
}

.footer {
  composes: flexVertical;
  margin-top: 48px;
}