.roleDescription {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin-bottom: 24px;
  color: #a6a2a2;
}

.roleDescription ul {
  padding-left: 20px;
}

.selectRoleHolder {
  margin: 24px 0 7px 0;
}

.selectRoleLabel {
  margin-right: 16px;
}

.selectedRole {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.selectedRoleDropdownContent {
  padding: 5px 13px;
  min-width: 145px;
  width: auto;
}

.selectedRoleIcon {
  position: relative;
  top: -1px;
  margin-left: 7px;
}

.selectedRoleIcon path {
  fill: #3f3f3f;
}
