.container {
  text-align: center;
  padding: 200px 0;
}

.title {
  font-weight: var(--fw-bold);
  font-size: 26px;
  line-height: 1.5;
  color: var(--c-blue);
  margin: 0;
}

.text {
  margin: 12px 0 0 0;
  font-weight: var(--fw-medium);
  font-size: 16px;
  line-height: 1.5;
  color: var(--c-text-gray);
}

.submitButton {
  width: 255px;
  text-align: center;
  height: 42px;
  margin: 40px auto 0;
  font-weight: var(--fw-medium);
}

.plans {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 50px 0 0 0;
  gap: 24px;
}

.plans > * {
  min-width: 318px;
}

.invitationHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.invitationControls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  gap: 12px;
}

.invitationControls > * {
  min-width: 100px;
  font-weight: 600;
}