.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controls {
  margin-top: 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.controls > div {
  display: flex;
  align-items: center;
}

.addButton {
  width: 103px;
  align-self: flex-end;
}