.container {
  position: relative;
  border: 1px solid var(--c-border);
  border-radius: var(--input-border-radius);
  overflow: auto;
  max-height: 120px;
  font-size: 0;
  z-index: 0;
}

.textarea {
  composes: textarea from "../Textarea/Textarea.module.css";
  width: 100%;
  border: 0;
  overflow: hidden;
  color: transparent;
  caret-color: var(--c-text);
  padding-right: 30px !important;
}

.highlighter {
  composes: textarea from "../Textarea/Textarea.module.css";
  border: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  pointer-events: none;
  white-space: pre-wrap;
  word-break: break-word;
  overflow: hidden;
  padding-right: 30px !important;
}

.highlightedWord {
  color: #b4b0b0;
  background: none;
  padding: 0;
}
