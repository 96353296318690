.pill {
  font-size: 12px;
  font-weight: 500;
  height: 23px;
  padding: 0 12px;
  align-items: center;
  justify-content: center;
  border-radius: 48px;
  display: inline-flex;
}
