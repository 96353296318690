.holder {
  padding: 19px 29px;
  border-radius: 8px;
  border: 1px solid var(--c-blue);
}

.item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.item small {
  font-size: 12px;
  margin-left: -2px;
}

.itemTitle {
  color: var(--c-black);
  font-size: 14px;
}

.itemValue {
  font-weight: var(--fw-medium);
  font-size: 20px;
}