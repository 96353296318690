.container {
  flex: 1;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container td {
  vertical-align: top;
}

.tdDomain {
  min-width: 250px;
}

.tdReason {
  min-width: 400px;
}

.tdDate {
  min-width: 110px;
}

.userLink {
  font-size: inherit;
  text-decoration: none;
}

.userLink:before {
  display: none;
}
