.tag {
  background: var(--c-blue-dimmed);
  color: var(--c-blue);
  border-radius: 15px;
  font-size: 12px;
  line-height: 20px;
  padding: 0 10px;
  cursor: default;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tag.outline {
  background: none;
  border: 1px solid var(--c-blue);
  color: var(--c-blue);
}

.tag.underline {
  background: none;
  border: none;
  color: var(--c-blue);
  text-decoration: underline;
  padding-left: 0;
  padding-right: 0;
}

.tag.underline:hover {
  text-decoration: none;
}

.red {
  background: var(--c-red-dimmed);
  color: var(--c-red);
}

.red.outline {
  background: none;
  border: 1px solid var(--c-red);
  color: var(--c-red);
}

.red.underline {
  background: none;
  border: none;
  color: var(--c-red);
  text-decoration: underline;
  padding-left: 0;
  padding-right: 0;
}

.red.underline:hover {
  text-decoration: none;
}

.gray {
  background: var(--c-border-dimmed);
  color: var(--c-text-gray);
}

.tag svg path {
  fill: currentColor;
}