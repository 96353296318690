.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 16px 13px 23px;
  border: 1px solid var(--c-blue-dimmed);
  border-radius: 8px;
  background-color: #F5F9FF;
  color: var(--c-blue);
}

.controls {
  display: flex;
  align-items: center;
  gap: 18px;
}

.controls > * {
  cursor: pointer;
}