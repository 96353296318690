.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 124px;
  padding: 6px 16px;
  margin: 0;
  font-family: Montserrat-Medium !important;
  font-size: 14px;
  line-height: normal;
  -webkit-appearance: none !important;
  box-shadow: none;
  border: none;
  border-radius: 48px;
  cursor: pointer;
  background: none;
  text-decoration: none !important;
  color: var(--c-black);
  transition: 0.2s;
}

.button:hover {
  opacity: 0.7;
}

.button:disabled,
.button:disabled:hover {
  opacity: 0.4;
  cursor: initial;
}

.default {
  border: 1px solid var(--c-cyan);
  color: var(--c-cyan);
}

.text:hover {
  opacity: 1;
  background: rgba(0, 0, 0, 0.1);
}

.primary {
  border: 1px solid var(--c-cyan);
  background: var(--c-cyan);
  color: #fff;
}

.link {
  color: var(--c-cyan);
}
