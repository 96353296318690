.pagination {
  display: flex;
  align-items: stretch;
  padding: 0;
  user-select: none;
  margin: 0 !important;
}

.pagination * {
  outline: none;
  list-style-type: none;
  -webkit-user-select: none;
}

.pagination :global li:not(.previous):not(.next) {
  margin: 0 3px;
}

.pagination li a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 0 9px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 13px;
  transition: all 0.15s ease-in;
}

.pagination :global li:not(.previous):not(.next):not(.selected) a:hover {
  background: var(--c-blue-dimmed);
}

.pagination :global li.selected a {
  background: var(--c-blue-dimmed);
  color: var(--c-blue);
}

.pagination :global .previous {
  margin-right: 12px;
}
.pagination :global .next {
  margin-left: 12px;
}

.pagination :global .previous svg,
.pagination :global .next svg {
  width: 15px !important;
  height: auto !important;
  position: relative;
}

.pagination :global .previous span,
.pagination :global .next span {
  line-height: 0;
}

.pagination :global .next svg {
  top: 1px;
  left: 1px;
}

.pagination :global .previous svg {
  right: 1px;
}

.pagination :global .previous svg {
  transform: rotate(-180deg);
}

.pagination :global .previous.disabled,
.pagination :global .next.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.spread {
  align-self: flex-end;
  margin: 0 17px 4px;
}

.spread span {
  color: rgba(0, 0, 0, 0.3);
}
