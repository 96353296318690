.holder {
  display: flex;
  align-items: center;
}

.imageHolder {
  margin-right: 16px
}

.title {
  font-size: 16px;
  line-height: 20px;
  color: var(--c-blue);
  margin-bottom: 4px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.company {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
}
