.dimmedLabel .label {
  font-size: 13px;
  color: var(--c-text-dimmed);
}

.label {
  cursor: pointer;
  position: relative;
  font-size: 14px;
  line-height: 1.23;
  color: var(--c-blue);
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
}

.label:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  transform: translateY(-1px);
  border-top: 1px dashed currentColor;
}
