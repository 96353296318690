.__dropdown {
  width: 100%;
  font-size: 14px;
  position: relative;
}

.__dropdown--error {
  color: red;
  font-size: 10px;
}

.__dropdown--error-hidden {
  display: none;
}

.__dropdown--select {
  display: flex;
  align-items: center;
  width: 100%;
  height: 34px;
  border-radius: 3px;
  border: solid 1px #ccc;
  padding: 0 10px;

  justify-content: space-between;
  cursor: pointer;
  outline: none;
}

.__dropdown--select-hidden {
  display: none;
}

.__dropdown--select-error {
  width: 100%;
  height: 34px;
  border-radius: 3px;
  border: solid 1px red;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.__dropdown--value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.__dropdown--value-none {
  color: #9b9b9b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.__dropdown--carrot {
  background: url("./dropdown.svg") no-repeat center;
  width: 14px;
  height: 16px;
}

.__dropdown--input-hidden {
  display: none;
}

.__dropdown--optionsContainer {
  width: 100%;
  position: absolute;
}

.__dropdown--optionsContainerTriangle-hiden {
  display: none;
}

.__dropdown--options {
  width: 100%;
  position: absolute;
  border-radius: 3px;
  border: solid 1px #9b9b9b;
  z-index: 10;
  max-height: 121px;
  overflow-y: scroll;
  border-top: none;
}

.__dropdown--option:first-child {
  border-top: none;
}

.__dropdown--option.__dropdown--option-selected {
  color: #208bfe;
}

.__dropdown--options::-webkit-scrollbar {
  display: none;
}

.__dropdown--options-hidden {
  display: none;
}

.__dropdown--option {
  height: 40px;
  border-top: solid 1px #9b9b9b;
  background-color: white;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.__dropdown--option:hover {
  background-color: #f2f2f2;
  color: #208bfe;
}

.__dropdown--option-top {
  border-top: none;
}

.__dropdown--newEntry {
  color: #208bfe;
  text-align: right;
  padding-bottom: 10px;
  font-size: 12px;
  letter-spacing: -0.49px;
  color: #208bfe;
}

.__dropdown--newEntry:active {
  color: #016fe4;
}

.__dropdown--newEntry-hidden {
  visibility: hidden;
}
