.menuItem {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 20px 0 !important;
  background: transparent !important;
  transition: 0.2s;
}

.menuItem:not(.active):hover {
  opacity: 0.6;
}

.text {
  font-family: Montserrat-Medium !important;
  font-size: 16px;
  line-height: normal;
  color: var(--c-cyan);
  transition: 0.2s;
}

.link:hover {
  text-decoration: none;
}

.menuItem.active .text {
  color: var(--c-black);
}
