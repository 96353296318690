.container {
  display: inline-flex;
  align-items: center;
}

.selectContainer {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  padding: 7px 14px 7px 14px;
  border-right: 22px;
  background: var(--c-input-bg);
  color: var(--c-text);
  height: 30px;
}

.chevron {
  margin-left: 6px;
}

.chevron svg path {
  fill: var(--c-text)
}

.label {
  margin-right: 11px;
  font-size: 12px;
  font-weight: 500;
  color: #868686;
}
