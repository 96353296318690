.content {
  padding: 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}

.title {
  margin-bottom: 24px;
}

.title :global .icon-workable {
  margin-right: 8px;
  position: relative;
  top: 1px
}

.divider {
  margin: 24px 0 32px;
  border: 0;
  border-bottom: 1px solid #D6D6D6;
}