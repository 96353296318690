.button {
  display: inline-flex;
}

.content {
  max-height: 205px;
  margin-top: 8px;
  width: 200px;
  overflow: auto;
  position: absolute;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 1px, rgba(0, 0, 0, 0.1) 0 4px 11px;
  border-radius: 4px;
  z-index: 10000;
}

.divider {
  border-top: 1px solid var(--c-border);
  margin: 15px 0;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 7px;
  margin: 2px 0;
  cursor: pointer;
  transition: all 0.15s ease-in;
}

.item:hover {
  background: var(--c-blue-dimmed);
}
