.container {
  flex: 1;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.integrationIcon {
  --size: 15px;
  max-width: var(--size);
  max-height: var(--size);
  margin-left: 7px;
}

.container td {
  vertical-align: top;
}

.tdName {
  min-width: 200px;
}

.tdDate {
  min-width: 110px;
}

.candidateLink {
  font-size: inherit;
  text-decoration: none;
}

.candidateLink:before {
  display: none;
}
