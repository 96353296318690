.paymentSettings {
  margin: 32px 0;
}

.subTitle {
  line-height: 24px;
}

.explanatoryText {
  display: inline-block;
  margin-top: 8px;
  font-family: Montserrat-Medium !important;
  font-size: 14px;
  line-height: 24px;
  color: var(--c-dark-gray);
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: -16px 0;
}

.col {
  max-width: 100%;
  margin: 16px 0;
  margin-right: 44px;
}

.row .col:nth-child(1) {
  min-width: 30%;
}

.row .col:nth-child(2) {
  flex: 1;
}

@media (max-width: 767px) {
  .row {
    margin: -16px;
  }

  .col {
    margin: 16px;
  }
}
