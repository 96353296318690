.body {
  --gap: 40px;

  display: flex;
  justify-content: center;
}

.step + .step {
  margin-left: var(--gap);
}

.step {
  --color: var(--c-cyan);
  position: relative;
  padding-bottom: 16px;
}

.step.futureStep {
  --color: #d6d6d6;
}

.value {
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat-Semibold !important;
  font-size: 18px;
  line-height: 22px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--color);
  border: solid var(--color) 1px;
  user-select: none;
}

.step:not(:first-child) .value::before {
  content: "";
  position: absolute;
  background: var(--color);
  height: 1px;
  width: var(--gap);
  right: 102%;
  top: 50%;
}

.label {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  margin: 0;
  font-family: Montserrat-Medium !important;
  font-size: 10px;
  line-height: 12px;
  color: var(--color);
  white-space: nowrap;
}

.active .label {
  display: block;
}

.clickable {
  cursor: pointer;
}
