.holder {
  display: flex;
  position: relative;
}

.select {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  font-size: 16px;
  -webkit-appearance: none;
}

.select:disabled {
  cursor: initial;
}

.select option:disabled {
  background: var(--c-blue-dimmed);
  color: var(--c-blue);
}
