.head {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1000;
}

.title {
  padding: 24px 32px;
}

.tabs {
  justify-content: space-around;
  border-width: 2px;
}

.tab {
  position: relative;
  flex: 1;
  padding: 8px 10px;
  font-family: Montserrat-Medium !important;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  -webkit-appearance: none !important;
  color: var(--c-cyan);
  transition: 0.2s;
}

.tab:hover {
  color: var(--c-cyan);
  text-decoration: none;
  opacity: 0.6;
}

.tab:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background: var(--c-cyan);
  opacity: 0;
  transition: 0.2s;
}

.tab.active {
  color: var(--c-black);
}

.tab.active:hover {
  opacity: 1;
}

.tab.active:after {
  opacity: 1;
}

.tabContent {
  padding: 32px;
}
