.holder {
  position: relative;
  font-size: 0;
}

.holder.hasRightIcon .input {
  padding-right: 40px;
}

.holder.hasLeftIcon .input {
  padding-left: 30px;
}

.clearable:hover .clearButton {
  opacity: 1;
  visibility: visible;
  right: 9px;
  pointer-events: all;
}

.clearable:hover .input {
  padding-right: 30px;
}

.input {
  border: 1px solid var(--c-border);
  box-sizing: border-box;
  border-radius: var(--input-border-radius);
  transition: all 0.15s ease-in;
  font-size: 14px;
  line-height: 1.2;
  padding: 0 8px;
  height: 33px;
  outline: none;
  width: 100%;
}

.input::placeholder {
  color: var(--c-text-dimmed);
}

.input:hover,
.input:focus {
  border-color: var(--c-blue);
}

.input:read-only {
  border-color: var(--c-border);
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.rightIcon {
  composes: icon;
  right: 10px;
}

.leftIcon {
  composes: icon;
  left: 10px;
}

.clearButton {
  pointer-events: none;
  -webkit-appearance: none;
  box-shadow: none;
  border: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 7px;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: var(--c-blue) !important;
  font-size: 0;
  transition: all 0.15s ease-in;
}

.clearButton svg {
  width: 7px;
  height: auto;
}

.clearButton path {
  fill: #ffffff;
}

.clearButton > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.error {
  font-family: Montserrat-Medium !important;
  font-size: 12px;
  margin-top: 4px;
  line-height: normal;
  color: var(--c-red);
}

@media screen and (max-width: 1366px), screen and (max-height: 768px) {
  .input {
    height: 32px;
  }
}
