.savedSearch {
  font-size: 14px;
}

.dropdownContent {
  max-height: 400px;
  overflow-y: auto;
  width: 285px;
  padding: 20px 15px 15px 20px !important;
}

.sectionTitle {
  font-size: 10px;
  font-weight: bold;
  letter-spacing: -0.41px;
  color: #000000;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.itemTitle {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 30px;
}
