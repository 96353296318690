.container {
  padding-bottom: var(--footer-height);
  
  --max-width: 1140px;
}

.enrichmentCard {
  padding: 16px 24px 16px 24px;
  gap: 16px;
  border: 1px solid var(--c-border);
  border-radius: 8px;
  transition: 0.2s;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
}

.checkIconHolder {
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid var(--c-border);
}

.checkIcon {
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.titleHolder {
  display: flex;
  gap: 8px;
}

.enrichmentCard.active,
.enrichmentCard.active .checkIconHolder {
  border-color: var(--c-cyan);
}

.enrichmentCard.active .checkIcon {
  opacity: 1;
  visibility: visible;
}

.enrichmentCardTitle {
  margin-top: -1px;
  margin-bottom: 8px;
}

.enrichmentCardHelpText {
  margin: 0;
}

.table {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

.table thead th {
  background: var(--c-blue-dimmed);
  font-weight: 600;
}

.table td,
.table th {
  border: 1px solid var(--c-border);
  padding: 10px 20px;
}

.tdName {
  font-weight: 600;
}

.tdMappingStatus {
  width: 160px;
}

.previewList {
  padding: 0;
  margin: 0;
}

.previewList li {
  margin-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.previewList li:last-child {
  margin-bottom: 0;
}

.skipUnmapped {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;
}