.content {
  padding: 14px 0;
  z-index: 999;
  width: auto;
  max-height: unset;
}

.item {
  padding: 6px 16px;
  line-height: 1;
}

.itemInner {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  color: var(--c-blue);
  cursor: pointer;
}

.item:last-child {
  margin: 0
}

.itemInner:hover .checkbox {
  border-color: var(--c-blue);
}

.checkbox {
  margin-right: 8px;
}

.itemClearAll {
  composes: item;
}

.itemClearAll .itemInner:hover {
  text-decoration: underline;
}