.container {
  position: relative;
  display: flex;
  border-top: 3px solid;
  padding: 18px 20px 13px;
  width: 100%;
  box-shadow: 0 5px 10px 0 rgba(3, 25, 41, 0.1);
  background: #ffffff;
}

.success {
  border-color: #46b053;
  color: #46b053;
}

.danger {
  border-color: #ff503f;
  color: #ff503f;
}


@media screen and (max-width: 426px) {
  .container br {
    display: none;
  }
}
