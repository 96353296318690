.holder {
  height: 10px;
  width: 100%;
  background-color: #F2F2F2;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: var(--c-blue);
  border-radius: 8px;
  transition: width 0.15s ease-in-out;
}