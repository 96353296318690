.holder {
  max-width: 100%;
  height: calc(100% - 74px);
}

.holder :global .react-kanban-board {
  height: calc(100% - var(--pagination-height));
  overflow: auto !important;
}

.holder :global .react-kanban-column {
  width: 228px;
  background: #F5F5F5;
  overflow: hidden;
}

.candidatePreview {
  position: fixed;
  height: calc(100% - var(--app-header-height));
  top: var(--app-header-height);
  right: 0;
  bottom: 0;
  overflow: auto;
}

.footer {
  padding: 0 24px;
  height: var(--pagination-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.footer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-top: 1px solid var(--c-border);
}

.footerItem {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--c-blue);
}

.footerItem :global .icon-arrow-down-to-line {
  position: relative;
  top: 1px;
}

.footerItem :global .icon-archive {
  position: relative;
  top: 1px;
}

.footerItem :global .icon-archive svg {
  width: 16px;
  height: auto;
}

.footerItemIcon {
  margin-right: 8px;
}