.discover {
  display: flex;
  height: 100%;
}

:root {
  --discover-header-height: 70px;
}


@media screen and (max-width: 1366px), screen and (max-height: 768px) {
  :root {
    --discover-header-height: 60px;
  }
}
