.body {
  padding: 25px 30px 48px;
  min-height: 415px;
}

.info {
  margin-bottom: 24px;
}

.info * {
  text-align: center;
}

.title {
  font-family: Montserrat-SemiBold !important;
  font-size: 18px;
  line-height: 22px;
}

.subtitle {
  font-family: Montserrat-Medium !important;
  font-size: 12px;
  line-height: 15px;
  color: #868686;
  margin: 8px 0 0;
}

.close {
  position: absolute;
  top: 17px;
  right: 24px;
  cursor: pointer;
}

.close svg {
  fill: var(--c-cyan);
}

@media (min-width: 576px) {
  .body {
    padding: 25px 67px 48px;
    min-height: 415px;
  }
}
