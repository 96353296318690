.button {
  -webkit-appearance: none !important;
  box-shadow: none;
  border: 1px solid transparent;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 48px;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  background: none;
  text-decoration: none !important;
}

.default {
  background: var(--c-cyan);
  color: #ffffff !important;
}

.outline {
  color: var(--c-cyan);
  border-color: var(--c-cyan);
}

.dangerOutlined {
  color: var(--c-red);
  border-color: var(--c-red);
}

.danger {
  background: var(--c-red);
  border-color: var(--c-red);
  color: #ffffff;
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.leftIcon {
  margin-right: 6px;
  font-size: 0;
}

.leftIcon path {
  fill: currentColor;
}

.rightIcon {
  margin-left: 6px;
  font-size: 0;
}

.leftIcon path {
  fill: currentColor;
}
