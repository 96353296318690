.container {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 30px 0;
}

.imageHolder {
  margin-right: 20px;
}

.imageHolderHidden {
  opacity: 0;
  visibility: hidden;
}

.section {
  padding-top: 2px;
  position: relative;
  overflow: hidden;
}

.sectionTitle {
  margin-bottom: 7px;
}

.mainSection {
  composes: section;
  width: 450px;
  flex-shrink: 0;
}

.title {
  font-size: 16px;
  line-height: 20px;
  color: var(--c-blue);
  margin-bottom: 4px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.25s ease-in;
}

.title:hover {
  text-decoration: none;
}

.archivedLabel {
  font-size: smaller;
  margin-left: 15px;
  color: var(--c-text-dimmed);
}

.company {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.socials {
  margin-top: 8px;
}

.description {
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
  color: var(--c-text-dimmed);
}

.divider {
  margin: 0 27px 0 18px;
  border-left: 1px solid var(--c-border-dimmed);
  align-self: stretch;
}

.findMatchButton {
  min-width: 145px;
}

.noData {
  font-size: 14px;
  color: var(--c-text-dimmed);
}

.inviteLink {
  font-size: inherit;
}

.candidateItem {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
}

.candidateInitials {
  flex-shrink: 0;
  margin-right: 10px;
}

.candidateName {
  font-size: 14px;
  line-height: 18px;
  color: var(--c-blue);
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.candidatePosition {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--c-text-dimmed);
  display: flex;
  align-items: baseline;
}

.collaboratorsList {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.collaboratorsList > * {
  flex-shrink: 0;
}

.collaboratorItem {
  cursor: pointer;
  margin-right: 4px;
}

.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-top: 7px;
}

.tags > * {
  margin-left: 10px;
  margin-bottom: 10px;
  cursor: initial;
}
