:root {
  --c-blue: #208bfe;
  --c-blue-dimmed: #dfecff;
  --c-gray: #787878;
  --c-border: #d6d6d6;
  --c-border-dimmed: #f0f0f0;
  --c-disabled: #d7d7d7;
  --c-text: #222222;
  --c-text-dimmed: #b0b0b0;
  --c-text-gray: #5f5f5f;
  --c-red-dimmed: #ffdfdf;
  --c-red: #f83e3e;
  --c-cyan: #408cf6;
  --c-black: #1d1d1d;
  --c-orange: #ff8d69;
  --c-yellow-text: #ba7815;
  --c-yellow-bg: #FFF8E5;
  --c-dark-gray: #868686;
  --c-azure-blue: #2044ab;
  --c-input-bg: #F5F5F5;

  --c-fb: #4968ad;
  --c-tw: #49a1eb;
  --c-in: #3375a4;
  --c-gh: #25292e;
  --c-db: #d95988;
  --c-gp: #db4a39;

  --left-padding: 32px;
  --header-height: 50px;
  --input-border-radius: 2px;

  --app-header-height: 44px;
  --pagination-height: 50px;
  
  --fw-regular: 400;
  --fw-medium: 500;
  --fw-semibold: 600;
  --fw-bold: 700;
}

.app {
  height: calc(100% - var(--header-height));
}

.max-width {
  padding-top: 20px;
  max-width: 1100px;
  margin: 0 auto;
}

/*--------- slide-down-transition ---------*/
.slide-down-transition-enter {
  transform: translateY(-50%);
  opacity: 0;
}

.slide-down-transition-enter.slide-down-transition-enter-active {
  transform: translateY(0);
  transition: all 0.3s ease-out;
  opacity: 1;
}

.slide-down-transition-leave {
  transform: translateY(0);
  opacity: 1;
}

.slide-down-transition-leave.slide-down-transition-leave-active {
  transform: translateY(-50%);
  transition: all 0.3s ease-out;
  opacity: 0;
}
/*--------- slide-down-transition ---------*/


/*--------- notifications-slide-down-transition ---------*/
.notifications-slide-down-transition-enter {
  transform: translate(-50%, -50%);
  opacity: 0;
}

.notifications-slide-down-transition-enter.notifications-slide-down-transition-enter-active {
  transform: translate(-50%, 0);
  transition: all 0.3s ease-out;
  opacity: 1;
}

.notifications-slide-down-transition-leave {
  transform: translate(-50%, 0);
  opacity: 1;
}

.notifications-slide-down-transition-leave.notifications-slide-down-transition-leave-active {
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-out;
  opacity: 0;
}
/*--------- notifications-slide-down-transition ---------*/
