.card {
  height: 102px;
  border: 1px solid;
  border-radius: 8px;
  padding: 6px;
  position: relative;
  width: 212px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  /* todo lets keep it here for now, I know we should not mix position styles with appearance */
  margin-bottom: 12px;
}

.overdue {
  background: #f3e9e9 !important;
  border-color: #d40000 !important;
}

.overdue .lastUpdated {
  color: #d40000;
}

.headline {
  display: flex;
  align-items: flex-start;
}

.mainInfo {
  /* needs to make text-overflow: ellipsis work */
  min-width: 1px;
}

.profileImage {
  flex-shrink: 0;
  width: 24px !important;
  height: 24px !important;
  font-size: 12px !important;
  margin-right: 4px;
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  margin-bottom: 1px;
  color: #1D1D1D;
  line-height: 15px;
}

.location {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  color: #868686;
  line-height: 10px;
}

.position {
  margin: 12px 0 0 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  color: #1D1D1D;
}

.lastUpdated {
  position: absolute;
  bottom: 6px;
  left: 6px;
  font-size: 10px;
  color: #868686;
}

.actions {
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.actionsItem {
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.actionsItem :global .icon-eye svg {
  width: 17px;
  height: auto;
}

.actionsItem :global .icon-note svg {
  width: 12px;
  height: auto;
}

.actionsItem :global .icon-dots-vertical-small {
  position: relative;
}