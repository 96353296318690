.notification {
  /* Overflow Intercom widget */
  z-index: 2147483002;
  position: fixed;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 20px;
  padding: 15px 20px;
  border-radius: 10px;
  min-width: 180px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 10px;
}

.notification.popupMode {
  transform: translate(-50%, 0);
}

.notification svg {
  width: 100%;
  height: 100%;
}

.notification path {
  fill: currentColor;
}
