/* Contact Unlock Section */
.contact-header-container {
  min-height: 24px;
  margin-bottom: 8px;
}

.unlock-candidate-button {
  font-family: Montserrat-Medium !important;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.61px;
  text-align: right;
  color: #208bfe;
  margin-right: 3px;
  cursor: pointer;
}

.unlock-candidate-button-disabled {
  font-family: Montserrat-Medium !important;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.61px;
  text-align: right;
  margin-right: 3px;
  color: #d8d8d8;
}

.unlock-icon-container {
  display: flex;
}

.contact-bubble-text-container {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 1px;
  margin-top: -2px;
  width: 218px;
}

.contact-bubble-unlock-container {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.contact-bubble-row-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 50px;
  margin-left: -10px;
  margin-right: -10px;
}

.contact-bubble-container {
  min-width: 50px;
}

.contact-bubble-truncate {
  max-width: 185px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contact-icon-image {
  width: 20px;
}

/* Contact Toast Section */
.contact-toast-container .clipboard-success-toast-container {
  position: absolute;
  top: 1px !important;
  right: 0px;
  z-index: 1000;
  background-color: black !important;
  opacity: 0.75;
  padding: 0px 5px !important;
  border: 1px solid transparent;
  border-radius: 7px;
  box-shadow: none !important;
}

.contact-toast-container .clipboard-success-toast-content {
  background-color: black !important;
  font-size: 12px;
  color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px !important;
}

.contact-icon-container-selected {
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  align-self: center;
  margin: auto;
  width: 50px;
  height: 50px;
  background: radial-gradient(#208bfe 55%, transparent 57%, transparent 60%, #208bfe 62%, #208bfe 65%, transparent 67%)
    repeat scroll 0 0 transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon Details Section */
.icon-blue {
  color: #208bfe;
}

.icon-grey {
  color: #9b9b9b;
}

.contact-bubble-label {
  font-family: Montserrat-Medium !important;
  font-size: 12px;
  color: var(--c-text);
}

.icon-background-container {
  width: 41px;
  height: 41px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  align-self: center;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.small-icon-background-container {
  width: 12px;
  height: 13px;
  border-radius: 50%;
  text-align: center;
  align-self: center;
}

.social-filter-icon-background-container {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  align-self: center;
}

.align-super {
  vertical-align: super;
}

.display-none {
  display: none !important;
}

.hover {
  cursor: pointer;
}

.contact-icon-background-default {
  background-color: #d8d8d8;
}

.contact-icon-background-default-dark {
  background-color: #9b9b9b;
}

.social-filter-contact-icon-background-default {
  background-color: #4a4a4a;
}

.contact-icon-background-active {
  background-color: #208bfe;
}

/* Social logo active backgrounds */
.facebook-icon-background-active {
  background-color: #4267b2;
}

.twitter-icon-background-active {
  background-color: #00acee;
}
.linkedin-icon-background-active {
  background-color: #0e76a8;
}
.dribbble-icon-background-active {
  background-color: #ea4c89;
}
.github-icon-background-active {
  background-color: #24292e;
}
.google-plus-icon-background-active {
  background-color: #dd4b39;
}

.grey-border-bottom {
  border-bottom: 1px solid #d6d6d6;
}

.light-grey {
  color: #a8a8a8;
}

.space-evenly {
  justify-content: space-evenly;
}
.space-between {
  justify-content: space-between;
}

.no-select {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;
}

.grid-toggle-icon-container {
  width: 13px;
  height: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 3px;
}

.icon {
  margin-right: 5px;
}

@keyframes pulse {
  from,
  25%,
  75%,
  to {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }
}

.pendingIcon {
  animation-name: pulse;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}