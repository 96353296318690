:root {
  --candidates-page-header-height: 74px;
}

.select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.holder {
  height: var(--candidates-page-header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  flex-shrink: 0;
}

.flexRow {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.title {
  font-size: 18px;
  line-height: 1;
  color: #1D1D1D;
  display: inline-block;
}

.title svg path {
  fill: currentColor;
}

.link {
  text-decoration: none;
}

.companyName {
  font-size: 12px;
  color: var(--c-dark-gray);
}

.search {
  margin-left: 20px;
  min-width: 322px;
}

.viewSwitcher {
  margin-left: 32px;
  margin-right: 16px;
}

.discoverButton {
  margin-left: 16px;
  min-width: 155px;
}

.newCandidateButton {
  margin-left: 15px;
}

@media screen and (min-width: 1900px) {
  .holder {
    justify-content: unset;
  }
}