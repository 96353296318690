.holder {
  color: var(--c-yellow-text);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid var(--c-yellow-text);
  background: var(--c-yellow-bg);
  text-align: left;
  padding: 12px 16px;
  border-radius: 2px;
}

.holder :global .alert__link {
  cursor: pointer;
  text-decoration: underline;
}

.holder :global .alert__link:hover {
  text-decoration: none;
}