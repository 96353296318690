.cardList {
  /*max-height: 350px;*/
  /*overflow-y: auto;*/
}

.cardItem:last-child {
  margin-bottom: 0;
}

.addCardButton {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.plusIcon {
  margin-right: 12px;
}

.plusIcon svg {
  fill: var(--c-cyan);
}

.confirmTextWrapper {
  margin-bottom: 16px;
}

.confirmTextWrapper span {
  display: block;
  font-family: Montserrat-SemiBold !important;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  color: #b4b0b0;
}

.confirmTextWrapper .confirmHighlightedText {
  color: var(--c-black);
}
