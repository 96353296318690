.container {
  display: inline-flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
  font-size: 12px;
}

.chevron {
  margin-left: 6px;
}
.chevron svg {
  width: 10px
}
