.holder {
  background: #FFFFFF;
  border: 2px solid #F9F9F9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 8px 8px;
  padding: 8px 12px;
  /*width: 200px;*/
}

.holder input {
  font-size: 12px;
  height: 24px;
}

.label {
  font-size: 12px;
}

.daysOnStage {
  display: flex;
  align-items: center;
}
