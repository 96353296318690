.subdomainField {
  display: flex;
}

.subdomainInput {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.subdomainPredefinedDomainInput {
  border-left: 0;
  background: var(--c-border-dimmed);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 140px;
}