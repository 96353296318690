.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--c-cyan);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
  border-color: var(--c-border);
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px #4096ff1a;
}

.ant-form-item-feedback-icon {
  display: flex;
}

.ant-btn-primary {
  height: auto;
  line-height: 1.2;
  background-color: var(--c-cyan);
}

.ant-btn-primary:disabled {
  background-color: var(--c-cyan);
  color: #fff;
  opacity: 0.4;
}

.ant-form-item .ant-form-item-label > label {
  color: var(--c-black);
}

.ant-form-item .ant-form-item-explain-error {
  font-size: 12px;
}
