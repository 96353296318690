.holder {
  height: calc(100% - var(--app-header-height));
  display: flex;
  flex-direction: column;
}

.header {
  flex-shrink: 0;
  height: var(--discover-header-height);
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--c-border);
  padding-left: var(--left-padding);
}

.content {
  display: flex;
  flex: 1;
  height: calc(100% - var(--discover-header-height));
}

.contentRight {
  max-width: calc(100% - 330px);
  flex: 1;
  display: flex;
  flex-direction: column;
}

.holder.isTableView .contentHeader {
  border-bottom-color: transparent;
}