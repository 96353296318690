.holder {
  flex: 0 0 330px;
  display: flex;
  flex-direction: column;
  transition: flex-basis 0.15s ease-in;
}

.holder.expanded {
  flex: 0 0 500px;
}

.holder.expanded .expandButton > * {
  transform: translate(-50%, -50%) rotate(180deg);
}

.header {
  height: var(--discover-header-height);
  padding-left: var(--left-padding);
  display: flex;
  padding-top: 20px;
  align-items: baseline;
  flex-shrink: 0;
}

.title {
  margin-right: 15px;
}

.filtersHolder {
  flex: 1;
  overflow: auto;
  border-top: 1px solid var(--c-border);
  border-right: 1px solid var(--c-border);
  position: relative;
}

.resultsHolder {
  padding: 16px 20px 0 var(--left-padding);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.resultsInfo p {
  margin-bottom: 4px;
}

.resultsInfo p span {
  color: var(--c-text-dimmed);
}

.resultsInfo > * {
  margin-left: 15px;
}

.resultsInfo > *:first-child {
  margin: 0;
}

.divider {
  border-top: 1px solid var(--c-border);
}

.formDetails {
  user-select: none;
}

.formDetails > :first-child {
  margin-top: 20px;
}

.formDetails > :last-child {
  margin-bottom: 0px;
}

.filtersGroup {
  padding: 20px 20px 20px var(--left-padding);
}

.filtersGroup > details {
  user-select: none;
}

.filtersGroup > details > summary::-webkit-details-marker {
  display: none;
}

.detailsArrow {
  position: absolute;
  transform: rotate(0deg);
  right: 10px;
}

.detailsArrow svg path {
  fill: var(--c-blue);
}

.filtersGroup > details[open] .detailsArrow {
  transform: rotate(180deg);
}

.filtersGroup > details > summary {
    list-style: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.filtersGroup > details > summary {
  list-style: none;
  cursor: pointer;
  color: #000;
  display: flex;
  align-items: center;
}

.filtersGroup > :last-child {
  margin-bottom: 0;
}

.addressRow {
  display: flex;
}

.addressRow .city {
  flex: 1;
}

.addressRow .zip {
  width: 69px;
  margin-left: 10px;
  flex-shrink: 0;
}

@media screen and (max-width: 1366px), screen and (max-height: 768px) {
  .header {
    padding-top: 14px;
  }
  .resultsHolder {
    padding-top: 10px;
  }
  .filtersGroup {
    padding-top: 15px;
    padding-bottom: 17px;
  }
}
