.holder {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: -32px -32px 0 -32px;
}

.header {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
}

.title {
  display: inline-block;
}

.search {
  display: inline-block;
  min-width: 300px;
}

.filter {
  display: inline-flex;
  margin-left: 32px;
}

.content {
  flex: 1;
  padding-bottom: var(--pagination-height);
}

.pagination {
  padding-left: 24px;
  padding-right: 32px;
  flex-shrink: 0;
  position: fixed;
  bottom: 0;
  left: 330px;
  right: 0;
  background: #ffffff;
  z-index: 1;
}
