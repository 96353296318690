.container {
  text-align: center;
  padding: 200px 0;
}

.title {
  font-weight: var(--fw-bold);
  font-size: 26px;
  line-height: 1.5;
}

.title.success {
  color: var(--c-blue);
}

.text {
  margin: 12px 0 0 0;
  font-weight: var(--fw-medium);
  font-size: 16px;
  line-height: 1.5;
  color: var(--c-text-gray);
}

.submitButton {
  width: 255px;
  text-align: center;
  height: 42px;
  margin: 40px auto 0;
  font-weight: var(--fw-medium);
}
