.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controls {
  margin-top: 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.controls > div {
  display: flex;
  align-items: center;
}

.exportButton {
  min-width: 155px;
}
