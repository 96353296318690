.col {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.holder {
  composes: col;
  padding-right: 44px;
}

.row {
  display: flex;
  gap: 20px;
}

.row > * {
  flex: 1;
}

.progressBarCard {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.progressBarCardRow {
  display: flex;
  justify-content: space-between;
}

.progressBarCardValue {
  color: var(--c-black);
  font-size: 14px;
}

.progressBarCardValue.clickable {
  text-decoration: underline;
  cursor: pointer;
}