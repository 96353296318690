.item {
  border: solid #d6d6d6 1px;
  border-radius: 8px;
  padding: 12px 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  max-height: 352px;
}

.isActive {
  background: rgba(64, 140, 246, 0.1);
}

.form .formLabel {
  font-family: Montserrat-Medium !important;
  font-size: 14px;
}

.form .formControl {
  padding: 9px 12px;
  border-color: var(--c-border);
  border-radius: 2px;
}

.form .select > div {
  border-color: var(--c-border);
  border-radius: 2px;
}

.form .formControl {
  line-height: 14px;
}

.form .formControl input {
  font-family: Montserrat-Medium !important;
  font-size: 12px;
  line-height: 14px;
}

.form .formControl .form .formControl input::placeholder {
  color: var(--c-border);
}

.form .select .chevronIcon svg {
  fill: var(--c-cyan);
}

.form .select :global(.ant-select-selection-item),
.position {
  font-family: Montserrat-Medium !important;
  font-size: 12px;
}

.options :global(.ant-select-item-option-content) {
  font-size: 12px;
}

.planName {
  font-family: Montserrat-Bold !important;
  font-size: 16px;
  line-height: 24px;
  margin: 0 auto 0 12px;
}

.assignButton {
  font-family: Montserrat-Medium !important;
  font-size: 12px;
  background: none;
  border: none;
  color: #408cf6;
}

.member {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  padding-left: 10px;
  overflow: hidden;
  text-align: right;
}

.memberName {
  width: 100%;
  margin: 0;
  overflow: hidden;
  font-family: Montserrat-Medium !important;
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #1d1d1d;
}

.memberRole {
  margin: 4px 0 0 0;
  font-family: Montserrat-Medium !important;
  font-size: 10px;
  line-height: 12px;
  color: #868686;
}

.editButton {
  padding: 5px;
  margin-left: 11px;
  margin-right: -16px;
  background: none;
  border: none;
}

.text {
  min-height: 90px;
  margin-bottom: 40px;
  font-family: Montserrat-Medium !important;
  font-size: 12px;
  color: #868686;
}

.text p {
  margin-bottom: 20px;
}

.text span {
  color: #1d1d1d;
}

.text ul {
  padding-left: 20px;
  margin-bottom: 0;
}

.penIcon svg {
  fill: var(--c-cyan);
}

:global .ant-select-selection-placeholder {
  color: var(--c-text-dimmed) !important;
  font-size: 12px;
}