.textarea {
  composes: input from "../Input/Input.module.css";
  padding-top: 7px;
  padding-bottom: 8px;
  resize: none;
  margin: 0;
  height: auto;
}


@media screen and (max-width: 1366px), screen and (max-height: 768px) {
  .textarea {
    padding-bottom: 7px;
  }
}

