.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 2px solid var(--c-border);
}

.tab {
  font-size: 14px;
  line-height: 1.2;
  border: 0;
  cursor: pointer;
  text-align: center;
  padding: 0 30px 8px 30px;
  -webkit-appearance: none !important;
  background: none;
  position: relative;
  color: var(--c-blue);
}

.tab:not(.tabDisabled):hover {
  color: var(--c-text);
  text-decoration: none;
}

.tabActive {
  color: var(--c-text);
}

.tabDisabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.tabActive:before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  border-bottom: 2px solid var(--c-blue);
}
