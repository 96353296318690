/* Company Project Section */
.CompanyProject__project-name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 10px 9px 2px;
  margin-bottom: 9px;
  height: 24px;
  width: 100%;
}

.CompanyProject__project-name-container:hover {
  cursor: pointer;
  background-color: #f2f2f2;
  color: #0f88ff;
}

.CompanyProject__project-name-container:last-child {
  margin-bottom: initial;
}

.CompanyProject__candidate-project-name-icon-container {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.CompanyProject__candidate-project-name-icon-container {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.CompanyProject__candidate-project-icon-container {
  width: 11px;
  height: 11px;
  visibility: hidden;
}

.CompanyProject__candidate-project-icon-container-checked,
.CompanyProject__project-name-container:hover > .candidate-project-icon-container-checked {
  width: 13px;
  height: 10px;
  cursor: pointer;
  background: url("../../assets/icons/checkmark.svg");
  background-repeat: no-repeat;
  background-color: transparent;
  border-radius: 50%;
}

.CompanyProject__name {
  font-family: Montserrat-Medium !important;
  margin-left: -2px;
  margin-right: 0.5rem;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: -0.61px;
  color: #4a4a4a;
}

.CompanyProject__name:hover {
  color: #000000;
}

.CompanyProject__add-remove-button-container {
  display: none;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 36px;
  letter-spacing: 0.19px;
  text-align: right;
  color: #208bfe;
  font-weight: bold;
}

.CompanyProject__project-name-container:hover .CompanyProject__add-remove-button-container {
  display: flex;
}

.CompanyProject__candidate-project-icon-container,
.CompanyProject__candidate-project-icon-container-checked {
  margin-left: 2px;
  margin-right: 2px;
  width: 13px;
  height: 10px;
}

/* Generic Themes */
.light-grey {
  color: #9b9b9b;
}

.display-none {
  display: none;
}

.download {
  color: #27a0fe;
  cursor: pointer;
}
