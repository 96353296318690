.holder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  border-top: 1px solid var(--c-border);
  border-bottom: 1px solid var(--c-border);
  margin-left: -8px;
  margin-right: -9px;
  margin-bottom: 4px;
  border-right: 1px solid var(--c-border);
  background: #ffffff;
}

:global .react-kanban-column > div:first-child {
  position: sticky;
  top: 0;
  z-index: 999;
}

.stageName {
  cursor: pointer;
}

.title {
  min-width: 1px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 174px;
}

.sort {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  overflow: hidden;
}

.sortSelect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.settings {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}