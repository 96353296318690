.input {
  background: var(--c-input-bg);
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 22px;
  min-width: 194px;
  border-color: transparent;
  height: 30px;
  font-size: 13px;
}

.input::placeholder {
  color: #868686 !important;
}

.input:hover,
.input:focus {
  background: none;
}

.rightIcon {
  right: 15px;
}
