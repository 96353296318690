.modal :global .modal-content {
  width: 420px;
  min-height: initial;
}

.text {
  text-align: center;
  margin-bottom: 35px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.buttonRow {
  display: flex;
  justify-content: center;
  padding: 0 5px;
}

.cancel {
  margin-right: 20px;
}

.button {
  min-width: 120px;
  height: 32px;
}
