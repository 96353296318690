.modal :global(.modal-content) {
  padding: 25px 88px;
  padding-bottom: 48px;
}

.footer {
  margin: 24px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 575px) {
  .modal :global(.modal-content) {
    padding: 25px 40px;
  }
}
