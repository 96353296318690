.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controls {
  margin-top: 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.controls > div {
  display: flex;
  align-items: center;
}

.discoverButton {
  min-width: 155px;
}

.exportButton {
  composes: discoverButton;
  position: relative;
}

.input {
  background: var(--c-input-bg);
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 22px;
  min-width: 194px;
  border-color: transparent;
  height: 30px;
  font-size: 13px;
}

.input:hover,
.input:focus {
  background: none;
}

.rightIcon {
  right: 15px;
}

.addWorkableButton {
  margin: 8px 0 0 8px;
}
