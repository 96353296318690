.group {
  padding: 8px;
  border-radius: var(--input-border-radius);
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid var(--c-border);
}

.filter {
  display: flex;
  align-items: center;
  gap: 8px
}

.inputs {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
}

.inputs > * {
  flex: 1 0 200px;
}

.deleteButton {
  -webkit-appearance: none;
  background: none;
  border: 0;
  font-size: 0;
  margin-left: 20px;
}

.deleteButton:disabled {
  opacity: 0.5;
}

.deleteButton svg {
  width: 12px;
  height: auto;
}

.deleteButton path {
  fill: var(--c-red)
}

.connectorButton {
  flex-shrink: 0;
  font-size: 12px;
  padding: 2px 0;
  font-weight: var(--fw-bold);
  width: 60px;
  text-align: center;
  border-radius: 40px;
  background: var(--c-cyan);
  transition: all 0.2s ease-in;
  -webkit-appearance: none;
  border: 0;
  color: #ffffff;
}

.connectorButton:disabled {
  opacity: 0.5;
}

.connectorButton:not(:disabled):hover {
  background: var(--c-azure-blue);
}

.connectorHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.connectorHolderConnector {
  border-left: 2px solid var(--c-text);
  height: 22px;
}