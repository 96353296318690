.card {
  padding: 16px 12px 16px 58px;
  border: 1px solid var(--c-border);
  border-radius: 8px;
  transition: 0.2s;
  position: relative;
  cursor: pointer;
}

.checkIcon {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 16px;
  left: 20px;
  border: 2px solid var(--c-border);
  border-radius: 50%;
}

.titleHolder {
  display: flex;
  gap: 8px;
}

.active,
.active .checkIcon {
  border-color: var(--c-cyan);
}

.disabled {
  cursor: not-allowed;
  background: rgba(244, 244, 244, 0.4);
}

.disabled .title,
.disabled .helpText,
.disabled .checkIcon {
  opacity: 0.4;
}

.active .checkIcon::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background: var(--c-cyan);
  border-radius: 50%;
}

.title {
  margin-bottom: 10px;
}

.helpText {
  margin: 0;
}