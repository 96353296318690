.holder {
  height: calc(100% - var(--candidates-page-header-height));
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: var(--pagination-height);
  position: relative;
}

.holder {
  --td-name-padding-left: 24px;
}

.table {
  width: 100%;
  table-layout: fixed;
}

.table td {
  overflow: hidden;
  text-overflow: ellipsis;
}

.table th {
  padding-bottom: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #868686;
  white-space: nowrap;
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 999;
}

.table th:before {
  content: '';
  border-bottom: 1px solid #D6D6D6;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.tr td {
  padding-top: 14px;
  padding-bottom: 14px;
  font-weight: 400;
  font-size: 14px;
  color: #1D1D1D;
  border-top: 1px solid #D6D6D6;
}

.tr:first-child td {
  border-color: transparent;
}

.table tbody tr:last-child td {
  border-bottom: 0;
}

.tdCheckbox {
  overflow: unset !important;
  padding: 0 8px 0 0;
  width: 38px;
  text-align: right;
  position: relative;
}

.checkbox {
  top: 1px;
  position: relative;
  display: inline-block;
}

.tdLocation {
  width: 150px;
}

.tdName {
  padding-left: var(--td-name-padding-left);
}

.name {
  cursor: pointer;
}

.name:hover {
  text-decoration: underline;
}

tbody .tdName > span:first-child:hover {
  cursor: pointer;
}

.tdHeadline {
  width: 400px;
}

.tdStage {
  padding-left: 24px;
}

.tdLastActivity {
  width: 140px;
}

.tdActions {
  width: 68px;
}

.tdActions svg {
  user-select: none;
}

.tdActions :global .icon-chevron-thin {
  color: #408CF6;
}

.tdActions :global .icon-chevron-thin svg {
  width: 14px;
  height: auto;
}

.actionsList {
  display: flex;
  align-items: center;
}

.actionsItem {
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.actionsItem svg path {
  fill: var(--c-blue)
}

.pagination {
  padding-left: 16px;
  padding-right: 32px;
  flex-shrink: 0;
  position: fixed;
  bottom: 0;
  left: 330px;
  right: 0;
  background: #ffffff;
  z-index: 1;
}

.detailsTr td {
  padding-top: 24px;
  vertical-align: top;
}

.detailsTr.last td {
  padding-bottom: 24px;
}

.detailsTdContent {
  padding-left: var(--td-name-padding-left);
}

.detailsSectionTitle {
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 8px;
  color: #868686;
}

.detailsSectionText {
  margin: 0;
  font-size: 14px;
  line-height: 17px;
  color: #1D1D1D;
}

.detailsLinks {
  display: flex;
}

.detailsLinks > a {
  color: var(--c-blue);
  text-decoration: none !important;
  cursor: pointer;
}

.detailsLinks > a:hover {
  text-decoration: underline;
}

.detailsLinksDivider {
  font-size: 12px;
  margin: 0 12px;
  flex-shrink: 0;
}

.footerItem {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  color: var(--c-blue);
}

.footerItem svg path {
  fill: currentColor;
}

.footerItem:disabled {
  color: #D6D6D6;
}

.footerItem :global .icon-arrow-down-to-line {
  position: relative;
  top: 1px;
}

.footerItem :global .icon-archive {
  position: relative;
  top: 1px;
}

.footerItem :global .icon-archive svg {
  width: 16px;
  height: auto;
}

.footerItemIcon {
  margin-right: 8px;
}