.layout {
  max-height: calc(100vh - 44px);
  height: calc(100vh - 44px);
}

.header {
  padding: 18px 40px;
  height: auto;
  font-family: Montserrat-Medium !important;
  font-size: 26px;
  line-height: normal;
  border-bottom: 1px solid var(--c-border);
  background: #fff;
  color: var(--c-black);
}

.wrapper {
  display: flex;
  max-height: calc(100vh - 113px);
  height: calc(100vh - 113px);
}

.menuButton {
  position: absolute;
  display: flex;
  right: -40px;
  padding: 7px;
  width: 40px;
  height: 40px;
  border-radius: 0 0 3px 0;
  border-bottom: 1px solid var(--c-border);
  border-right: 1px solid var(--c-border);
  background: #fff;
  cursor: pointer;
}

.menuButton svg {
  fill: var(--c-cyan);
}

.siderWrapper {
  position: relative;
  max-width: 330px;
  width: 0%;
  height: 100%;
  background: #fff;
  overflow: visible;
  transition: 0.2s;
  z-index: 2;
}

.siderWrapper.opened {
  width: 100%;
}

.sider {
  height: 100%;
  overflow: hidden;
  border-right: 1px solid var(--c-border);
}

.siderChildren {
  width: 330px;
  padding: 28px 40px;
}

.content {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
}

@media (max-width: 767px) {
  .siderWrapper {
    position: absolute;
  }

  .content {
    margin-top: 25px;
  }
}
