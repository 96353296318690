.sidebar {
  flex: 0 0 330px;
  border-right: 1px solid var(--c-border);
  height: 100%;
  overflow: auto;
}

.groupTitle {
  font-size: 16px;
  line-height: 19px;
  margin-left: var(--left-padding);
  color: #1D1D1D;
}

.groupTitle svg {
  fill: currentColor;
}

.link {
  font-size: 16px;
  line-height: 19px;
  color: #408CF6;
}

.link.active {
  color: #1D1D1D;
  text-decoration: none;
  cursor: default;
}

.link svg path {
  fill: currentColor;
}

.link:hover:not(.active) {
  color: #408CF6;
}

.link :global .icon-folder {
  position: relative;
  top: -2px
}

.link :global .icon-person svg {
  width: 12px;
  height: auto;
}

.linkListSection {
  display: inline-flex;
  align-items: baseline;
  composes: link;
  border-bottom: 1px solid var(--c-border);
  padding: 12px 16px 12px var(--left-padding);
  width: calc(100%);
  position: relative;
}

.linkListSection.opened .linkListItemToggle {
  transform: rotate(180deg) translateY(7px);
}

.linkListSection.opened {
  border-bottom-color: transparent;
}

.linkListItem {
  display: inline-flex;
  composes: link;
  padding: 12px 16px 12px calc(var(--left-padding) + 20px);
  width: calc(100%);
}

.linkListItemInner {
  display: inline-flex;
  composes: link;
  padding: 8px 16px 8px calc(var(--left-padding) + 20px);
  width: calc(100%);
}

.linkListItemInner:first-child {
  padding-top: 4px;
}

.linkListItemInner:last-child {
  padding-bottom: 12px;
  border-bottom: 1px solid var(--c-border);
}

.linkListItemToggle {
  position: absolute;
  top: 14px;
  right: 10px;
}

.linkListItemToggle svg path {
  fill: var(--c-blue);
}

.dashboardLinkHolder {
  padding: 48px 0 40px 0;
}

.noProjects {
  padding: 24px 24px 24px var(--left-padding);
  font-size: 12px;
  color: var(--c-dark-gray);
}