.choosePaymentMethod {
  padding-top: 8px;
}

.cardList {
  max-height: 220px;
  overflow-y: auto;
}

.cardItem:last-child {
  margin-bottom: 0;
}

.addPaymentMethodButton {
  display: flex;
  align-items: center;
  width: max-content;
  margin-top: 16px;
  margin-left: auto;
  font-size: 12px;
  color: var(--c-cyan);
  cursor: pointer;
}

.plusIcon {
  margin-right: 12px;
}

.plusIcon svg {
  fill: var(--c-cyan);
}
