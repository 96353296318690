.editButton svg {
  width: 16px;
  height: auto;
  cursor: pointer;
}

.cancelButton path {
  fill: var(--c-red);
}

.cancelButton svg {
  width: 16px;
  height: auto;
  cursor: pointer;
}
