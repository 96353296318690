.holder {
  margin-bottom: 10px;
}

.holder svg {
  width: 10px;
  height: auto;
  transform: rotate(-180deg);
}

.holder svg path {
  fill: currentColor;
}