.container {
  flex: 1;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container td {
  vertical-align: top;
}

.tdName {
  min-width: 200px;
}

.tdDate {
  min-width: 110px;
}