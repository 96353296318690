.holder {
  display: flex;
  align-items: baseline;
}

.label {
  color: var(--c-text-dimmed);
  margin-right: 13px;
}

.content {
  padding: 5px 13px;
  min-width: 145px;
  width: auto;
}

/* @todo duplicated styles */
.item {
  padding-left: 13px;
  padding-right: 13px;
  margin-left: -13px;
  margin-right: -13px;
}

.valueLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.valueLabelIcon {
  position: relative;
  top: -1px;
  margin-left: 7px;
}

.valueLabelIcon path {
  fill: #3f3f3f;
}

.selected {
  cursor: initial;
  font-weight: 600;
  color: #3F3F3F;
}

.selected:hover {
  background: transparent;
}
