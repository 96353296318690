.imageHolder {
  width: 80px !important;
  height: 80px !important;
  margin-right: 24px;
  font-size: 32px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.imageHolder:hover .imageOverlay {
  opacity: 1;
  visibility: visible;
}

.image {
  /* fixme we should not use !important here, something is not right with css loading order*/
  width: 100% !important;
  height: 100% !important;
  font-size: 32px;
}

.imageOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  border-radius: 50%;
  visibility: hidden;
  transition: all 0.15s ease-in;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}