.pagination {
  height: var(--pagination-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.pagination:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-top: 1px solid var(--c-border);
}

.pageInfo {
  font-size: 13px;
  line-height: 1.23;
  color: var(--c-text-dimmed);
}

.pageSizeSelect {
  display: inline-flex !important;
}
