.label {
  display: block;
  font-family: Montserrat, sans-serif;
}

.field .label {
  margin-bottom: 8px;
}

.error input {
  border-color: var(--c-red);
}

.errorText {
  display: block;
  margin-top: 4px;
  font-size: 12px;
  color: var(--c-red);
}

.supportText {
  display: block;
  margin-top: 4px;
  font-size: 12px;
}

.requiredSign {
  color: var(--c-red);
  font-size: 1em;
  top: -0.2em;
  left: 2px;
}

@media screen and (max-width: 1366px), screen and (max-height: 768px) {
  .field .label {
    margin-bottom: 4px;
  }
}